@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&family=Nunito&family=Quicksand:wght@500&display=swap');

$background-colour: #262336;
$selection-colour: #47A4A4;
$fixed-height: 85vh;

$colors: (
  "discord": #7289DA,
  "github": #333333,
  "twitter": #1DA1F2,
  "telegram": #0088CC,
  "steam": #000000,
  "twitch": #6441A4
);

* {
  box-sizing: border-box;
}

::-moz-selection {
  background: $selection-colour;
  color: white;
}

::selection {
  background: $selection-colour;
  color: white;
}

html {
  scroll-behavior: smooth;
  min-height: 100%;
}

body {
  background-blend-mode: overlay;
  background-attachment: fixed;
  background-position: center;
  background-image: url(https://cdn.floofy.dev/winterbg.jpg);
  background-color: #232323;
  background-size: cover;
  height: 100%;
}

.container {
  justify-content: center;
  font-family: 'Nunito', sans-serif;
  align-items: center;
  display: flex;
  height: 96vh; // not normal but it works i guess
  color: white;
}

.container-content {
  text-align: center;
  gap: 5rem;
}

@media only screen and (min-width: 1024px) {
  .container-content {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    text-align: left;
    position: relative;
    display: grid;
  }
}

.container-right {
  justify-content: center;
}

@media only screen and (min-width: 1024px) {
  .container-right {
    grid-column: span 3 / span 3;
    margin-left: 2.3rem;
  }
}

.heading-1 {
  font-family: 'Nunito', sans-serif;
  font-size: 2.3rem;
}

@media only screen and (max-width: 1024px) {
  .heading-1 {
    font-size: 1.3rem;

    .emoji {
      height: 17px;
      width: 17px;
    }
  }
}

.heading-2 {
  font-family: 'Quicksand', sans-serif;
}

@media only screen and (max-width: 1024px) {
  .heading-2 {
    font-size: 1.2rem;
  }
}

.avatar {
  border-radius: 50%;
  display: block;
  margin: auto;
}

@media only screen and (max-width: 1024px) {
  .avatar {
    height: 175px;
    width: 175px;
  }
}

@each $key, $val in $colors {
  .button-#{$key} {
    background-color: $val;
    display: inline-block;
    border-radius: 0.75rem;
    color: white;
    text-align: center;
    font-size: 1.2rem;
    padding: 1rem;
    width: 4.7rem;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    cursor: pointer;
    margin: 5px;
  }

  @media only screen and (max-width: 1024px) {
    .button-#{$key} {
      margin-top: 0.25rem;
      padding: 0.35rem;
      width: 3.2rem;
    }
  }
}

.social-row {
  text-align: center;
  display: hidden;
  gap: 0.75rem;
}

@media only screen and (min-width: 1024px) {
  .social-row {
    grid-template-columns: repeat(7, minmax(0, 1fr));
    margin: -0.20rem;
    text-align: center;
    position: absolute;
    display: grid;
  }
}

a {
  color: white;
}

a:link {
  text-decoration: none;
  color: white;
}

a:hover {
  text-decoration: none;
  color: white;
}

a:active {
  text-decoration: none;
  color: white;
}

a:visited {
  text-decoration: none;
  color: white;
}


.footer {
  font-size: 15px;
  height: 50px;
  margin-top: -50px;
  color: white;
  text-align: center;
  font-family: 'Nunito', sans-serif;
}
